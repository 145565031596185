const enTranslation = {
    home: {
        title: 'This is your home page',
        goto_profile: 'Go to the profile page',
    },
    main: {
        title: 'ALL ROADS LEAD TO ENDREX',
        text: 'Join endrex and get all the trading opportunities for both the trader and the client',
        sign_in: 'Sign In',
        sign_up: 'Sign Up',
        hint: 'Do you have any questions? Write <0>hello@endrex.net</0>',
    },
    exchange: {
        actions: {
            add: 'Add exchange',
        },
        provider: {
            binance: 'Binance',
        },
        empty: {
            title: 'Add your first exchange and start copy trade',
            body: 'Unlock all the features of our platform, explore copy trading and level up your account',
        },
    },
    profile: {
        joined: 'Joined',
        see_translation: 'See Translation',
        see_original: 'See Original',
        view_more: 'View More',
        view_less: 'View Less',
        edit: 'Edit Profile',
        add_social: 'Add Social',
        complete_profile: 'Add a <PublicName>Public Name</PublicName> and <Location>Location</Location> to complete your profile',
        current_profile: 'Current',
        type: {
            trader: 'Trader',
            client: 'Client',
        },
        tabs: {
            active_portfolios: 'Active Portfolios',
            closed_portfolios: 'Closed Portfolios',
            portfolios: 'Portfolios',
            exchanges: 'Exchanges',
            followers: 'Followers',
            following: 'Following',
            action_buttons: {
                visit_profile: 'Visit Profile',
                share: 'Share',
                report: 'Report',
            },
            copiers: 'Copiers',
            activity: 'Activity',
            activity_show_last: 'Shows the last 1000 events',
            activity_events_list: {
                close_portfolio: 'Closed portfolio <Link></Link>',
                create_portfolio: 'Created portfolio <Link></Link>',
                start_following: 'Started following <Link></Link>',
                stop_following: 'Stopped following <Link></Link>',
            },
        },
        username: 'Public Name',
        nickname: 'Nickname',
        location: 'Location',
        about: 'About',
        privacy: 'Privacy',
        view_followers: {
            label: 'Who can view my Followers',
            options: [
                {
                    label: 'All users',
                    value: 'ALL',
                },
                {
                    label: 'Only me',
                    value: 'ME',
                },
            ],
        },
        view_following: {
            label: 'Who can view my Following',
            options: [
                {
                    label: 'All users',
                    value: 'ALL',
                },
                {
                    label: 'Only me',
                    value: 'ME',
                },
            ],
        },
        is_private_title: 'Private account',
        is_private_text: `When your account is private, only the followers 
        that you approve can see your Exchanges.`,
        social: 'Social',
        messages: {
            nickname_non_unique: 'This Nickname is already taken',
            nickname_invalid: 'Invalid Nickname',
            nickname_available: 'This nickname is available',
            max_socials_links: 'The number of social links has been exceeded. Remove something to add a new one',
            publicname_only_latin_and_numbers: 'Only Latin and numbers',
            publicname_empty: 'Should not be empty',
            nickname_empty: 'Should not be empty',
            location_empty: 'Should not be empty',
            nickname_only_latin_and_numbers: 'Only Latin, numbers, and special characters (_ and .)',
            nickname_special_chars_edges: 'Characters cannot be at the beginning or end of text',
            socials_empty: 'Should not be empty',
            socials_too_long: 'Link is too long (max 128 symbols)',
        },
        countries: {
            popular: 'Popular Country',
            alphabet_list: 'Country A-Z',
            countries_list: {
                AF: 'Afganistan',
                AL: 'Albania',
                DZ: 'Algeria',
                AD: 'Andorra',
                AO: 'Angola',
                AG: 'Antigua and Barbuda',
                AR: 'Argentina',
                AM: 'Armenia',
                AU: 'Australia',
                AT: 'Austria',
                AZ: 'Azerbaijan',
                BS: 'Bahamas',
                BH: 'Bahrain',
                BD: 'Bangladesh',
                BB: 'Barbados',
                BY: 'Belarus',
                BE: 'Belgium',
                BZ: 'Belize',
                BJ: 'Benin',
                BT: 'Bhutan',
                BO: 'Bolivia',
                BA: 'Bosnia and Herzegovina',
                BW: 'Botswana',
                BR: 'Brazil',
                BN: 'Brunei',
                BG: 'Bulgaria',
                BF: 'Burkina Faso',
                BI: 'Burundi',
                CV: 'Cabo Verde',
                KH: 'Cambodia',
                CM: 'Cameroon',
                CA: 'Canada',
                CF: 'Central African Republic',
                TD: 'Chad',
                CL: 'Chile',
                CN: 'China',
                CO: 'Colombia',
                KM: 'Comoros',
                CG: 'Congo',
                CR: 'Costa Rica',
                HR: 'Croatia',
                CU: 'Cuba',
                CY: 'Cyprus',
                CZ: 'Czech Republic',
                DK: 'Denmark',
                DJ: 'Djibouti',
                DM: 'Dominica',
                DO: 'Dominican Republic',
                TL: 'East Timor',
                EC: 'Ecuador',
                EG: 'Egypt',
                SV: 'El Salvador',
                GQ: 'Equatorial Guinea',
                ER: 'Eritrea',
                EE: 'Estonia',
                SZ: 'Eswaniti',
                ET: 'Ethiopia',
                FJ: 'Fiji',
                FI: 'Finland',
                FR: 'France',
                GA: 'Gabon',
                GM: 'Gambia',
                GE: 'Georgia',
                DE: 'Germany',
                GH: 'Ghana',
                GR: 'Greece',
                GD: 'Grenada',
                GT: 'Guatemala',
                GN: 'Guinea',
                GW: 'Guinea Bissau',
                GY: 'Guyana',
                HT: 'Haiti',
                HN: 'Honduras',
                HU: 'Hungary',
                IS: 'Iceland',
                IN: 'India',
                ID: 'Indonesia',
                IR: 'Iran',
                IQ: 'Iraq',
                IE: 'Ireland',
                IL: 'Israel',
                IT: 'Italy',
                CI: 'Ivory Coast',
                JM: 'Jamaica',
                JP: 'Japan',
                JO: 'Jordan',
                KZ: 'Kazakhstan',
                KE: 'Kenya',
                KI: 'Kiribati',
                KP: 'North Korea',
                KR: 'South Korea',
                XK: 'Kosovo',
                KW: 'Kuwait',
                KG: 'Kyrgyzstan',
                LA: 'Laos',
                LV: 'Latvia',
                LB: 'Lebanon',
                LS: 'Lesotho',
                LR: 'Liberia',
                LY: 'Libya',
                LI: 'Liechtenstein',
                LT: 'Lithuania',
                LU: 'Luxembourg',
                MG: 'Madagascar',
                MW: 'Malawi',
                MY: 'Malaysia',
                MV: 'Maldives',
                ML: 'Mali',
                MT: 'Malta',
                MH: 'Marshall Islands',
                MR: 'Mauritania',
                MU: 'Mauritius',
                MX: 'Mexico',
                FM: 'Micronesia',
                MD: 'Moldova',
                MC: 'Monaco',
                MN: 'Mongolia',
                ME: 'Montenegro',
                MA: 'Morocco',
                MZ: 'Mozambique',
                MM: 'Myanmar',
                NA: 'Namibia',
                NR: 'Nauru',
                NP: 'Nepal',
                NL: 'Netherlands',
                NZ: 'New Zealand',
                NI: 'Nicaragua',
                NE: 'Niger',
                NG: 'Nigeria',
                MK: 'North Makedonia',
                NO: 'Norway',
                OM: 'Oman',
                PK: 'Pakistan',
                PW: 'Palau',
                PA: 'Panama',
                PG: 'Papua New Guinea',
                PY: 'Paraguay',
                PE: 'Peru',
                PH: 'Philippines',
                PL: 'Poland',
                PT: 'Portugal',
                QA: 'Qatar',
                RO: 'Romania',
                RU: 'Russia',
                RW: 'Rwanda',
                KN: 'Saint Kitts and Nevis',
                LC: 'Saint Lucia',
                VC: 'Saint Vincent and the Grenadines',
                WS: 'Samoa',
                SM: 'San Marino',
                ST: 'Sao Tome and Principe',
                SA: 'Saudi Arabia',
                SN: 'Senegal',
                RS: 'Serbia',
                SC: 'Seychelles',
                SL: 'Sierra Leone',
                SG: 'Singapore',
                SK: 'Slovakia',
                SI: 'Slovenia',
                SB: 'Solomon Islands',
                SO: 'Somalia',
                ZA: 'South Africa',
                SS: 'South Sudan',
                ES: 'Spain',
                LK: 'Sri Lanka',
                SD: 'Sudan',
                SR: 'Suraname',
                SE: 'Sweden',
                CH: 'Switzerland',
                SY: 'Syria',
                TW: 'Taiwan',
                TJ: 'Tajikistan',
                TZ: 'Tanzania',
                TH: 'Thailand',
                TG: 'Togo',
                TO: 'Tonga',
                TT: 'Trinidad and Tobago',
                TN: 'Tunisia',
                TR: 'Turkey',
                TM: 'Turkmenistan',
                TV: 'Tuvalu',
                UG: 'Uganda',
                UA: 'Ukraine',
                AE: 'United Arab Emirates',
                UK: 'United Kingdom',
                US: 'United States',
                UY: 'Uruguay',
                UZ: 'Uzbekistan',
                VU: 'Vanuatu',
                VA: 'Vatican City',
                VE: 'Venezuela',
            },
        },
    },
    settings: {
        tabs: {
            personal_info: {
                tab_title: 'Personal information',
                main_table: {
                    title: 'Main',
                    email: {
                        title: 'E-mail',
                        text: 'Protect your account and transactions',
                    },
                    phone: {
                        title: 'Phone Number',
                        text: 'Protect your account and transactions',
                    },
                    password: {
                        title: 'Password',
                        text: 'Login password ',
                    },
                    kyc: {
                        title: 'KYC',
                        text: 'Protect your account and transactions',
                        values: {
                            verified: 'Verified',
                            unverified: 'Unverified',
                            under_review: 'Under Review',
                            expiring: {
                                text: 'Expiring soon',
                                time: '{{daysCount}} left',
                            },
                            outdated: 'Outdated',
                        },
                    },
                    twoFactorAuth: {
                        title: '2FA  Google Authentication',
                        text: 'Login password is used to log in to your account',
                    },
                },
                login_history_table: {
                    title: 'Login History Log',
                    end_btn: 'End all sessions',
                },
                profile_management_table: {
                    title: 'Profile Management',
                    delete_account: {
                        title: 'Delete account',
                        text: 'This action will lead to the total deletion of all profiles and portfolios related to them',
                    },
                    delete_profile_btn: 'Delete Profile',
                },
                add_profile: {
                    trader: {
                        title: 'Become a Trader',
                        text: 'The user account will remain available and you can switch between them',
                    },
                    client: {
                        title: 'Add user profile',
                        text: 'The trader account will remain available and you can switch between them',
                    },
                },
                delete_account: {
                    title: 'Delete Account',
                    text: 'This action will lead to the total deletion of all profiles and portfolios related to them',
                },
                api_keys: {
                    title: 'API Management',
                    empty_text: 'Exchange is not connected yet',
                },
            },
            notifications: {
                tab_title: 'Notifications',
                notifications_table: {
                    rows: {
                        security: 'Security',
                        smart_trade: 'Smart Trade',
                        system_notification: 'System Notification',
                        marketing_notification: 'Marketing Notification',
                        bitcoin_vollatility_agent: 'Bitcoin Vollatility Agent',
                        api_keys_expiration: 'API Keys Expiration',
                    },
                    columns: {
                        email: 'E-mail',
                        sms: 'SMS',
                        website: 'Web Site',
                        telegram: 'Telegram',
                    },
                },
            },
            subscription: {
                tab_title: 'Subscription',
                next_payment_date: 'Next Payment Date',
                next_payment_total: 'Next Payment Total',
                current_plan: 'Current Plan',
                change_plan_btn: 'Change Plan',
                payment_table: {
                    title: 'Payment Information',
                    date: 'Date',
                    total: 'Total',
                    platform_fee: 'Platform Fee',
                    status: 'Status',
                    statuses: {
                        PAID: 'Paid',
                        UNPAID: 'Not paid',
                        IN_PROGRESS: 'In progress',
                    },
                    view_invoice_btn: 'View Invoice',
                    subscription_plan: 'Subscription Plan',
                },
                subscription_plan: {
                    title: 'Subscription Plan',
                    plans: {
                        starter: {
                            title: 'Starter',
                            description: 'Explorer Tier: SPOT only',
                        },
                        advanced: {
                            title: 'Advanced',
                            description: 'Increase your profits with unlimited semi-manual trading',
                        },
                        pro: {
                            title: 'Pro',
                            description: 'Increase your profits with unlimited semi-manual trading',
                        },
                    },
                    recommended_badge: 'Recommended',
                    copy_trading_limit: 'Copy-Trading Limit',
                    per_exchange: 'per exchange',
                    spot_copy: 'Spot Copy',
                    margin: 'Margin',
                    futures: 'Futures',
                    support: 'Support',
                    free: 'Free',
                    coming_soon: 'Coming soon',
                    select_plan_btn: 'Select plan',
                    cancel_plan: 'Cancel Plan',
                    monthly_renew: 'Monthly renew {{date}}',
                    subscription_expiration: 'Your "{{planName}}" plan will cease to be valid on {{date}} and you will automatically switch to the "Starter" plan'
                },
                payment_method: {
                    title: 'Payment Method',
                    main: 'Main',
                    secondary: 'Secondary',
                    platform_balance: 'Platform Balance',
                    add_balance: 'Add Balance',
                    payment_method_error: 'Payment method error',
                    add_new_method: 'Add new method',
                },
            },
        },
        sidebars: {
            add_new_payment: {
                steps: {
                    initial: {
                        title: 'Add new payment method',
                        type: {
                            title: 'Type',
                        },
                        name: {
                            title: 'Name',
                            placeholder: 'TOM SHELBY',
                        },
                        card_number: {
                            title: 'Card Number',
                            placeholder: '0000 0000 0000 0000',
                        },
                        expire_date: {
                            title: 'Expire Date',
                        },
                        cvv: {
                            title: 'CVV',
                            placeholder: '***',
                        },
                        btn_title: 'Save',
                    },
                    final: {
                        text: 'New payment method added',
                        btn_title: 'Continue',
                    },
                },
            },
            edit_payment_method: {
                steps: {
                    initial: {
                        title: 'Edit payment method',
                        type: {
                            title: 'Type',
                        },
                        name: {
                            title: 'Name',
                            placeholder: 'TOM SHELBY',
                        },
                        card_number: {
                            title: 'Card Number',
                            placeholder: '0000 0000 0000 0000',
                        },
                        expire_date: {
                            title: 'Expire Date',
                        },
                        cvv: {
                            title: 'CVV',
                            placeholder: '***',
                        },
                        save_btn_text: 'Save',
                        delete_btn_text: 'Delete payment method',
                    },
                    delete_method: {
                        text: 'Are you sure to delete {{cardNumber}} card?',
                        delete_btn_text: 'Yes, delete',
                        cancel_btn_text: 'Cancel',
                    },
                    final: {
                        text: 'Payment method edited',
                        continue_btn_text: 'Continue',
                    },
                },
            },
            delete_account: {
                title: 'Do you really want to delete <strong>Account?</strong>',
                warning_label: 'Warning',
                warning_text: 'This action is irreversible. Once a profile is deleted, all information cannot be recovered.',
                informational_points: {
                    first: 'Deleting your account will permanently remove all your data.',
                    second: 'You\'ll lose access to all platform services and features, and you\'ll need a new registration to use the platform again.',
                    third: 'Your contact information will be fully removed from the platform, stopping all future communications and updates.',
                },
                account_deletion: 'Account Deletion',
                account_has_been_deleted: 'Your account has been deleted',
                deletion_confirmation_checkbox_text: 'I confirm that I really want to delete my account without the possibility of data recovery',
                actions: {
                    confirmation: 'Yes, I want to delete account',
                    delete: 'Delete Account',
                    go_to_main_page: 'Go to Main page',
                },
            },
            change_subscription: {
                steps: {
                    initial: {
                        upgrade_title: 'Upgrading your subscription plan to <0>{{tarifName}}</0>?',
                        downgrade_title: 'Downgrading your subscription plan to <0>{{tarifName}}</0>?',
                        upgrade_hint: {
                            title: 'Seamless Transition',
                            text: 'No interruption to your current subscriptions during the upgrade; they will continue without interruption as you transition to your new plan',
                        },
                        downgrade_hint: {
                            title: 'Warning',
                            text: 'Please review your current portfolio setup to ensure compliance with the new limits and avoid disruption of your trading activities.',
                        },
                        upgrade_details: {
                            portfolios: 'Create more portfolios and more opportunities for trading and earning money',
                            trading_type: 'With an upgrade you unlock {{tradingTypes}} and gain access to diversify your investments up',
                        },
                        downgrade_details: {
                            portfolios: 'If you exceed the portfolio limits of your new plan, active Portfolio copy-trading subscriptions will be discontinued and automatically closed as soon as your current monthly subscription period ends.',
                            trading_type: 'The {{planName}} plan permits only {{tradingTypesAllowed}} trading activities. Any active {{tradingTypesMissing}} Portfolio Subscriptions must be closed before the downgrade',
                        },
                        subscription_start_date: 'New subscription plan will start {{date}}',
                        continue_btn: 'Change Plan',
                    },
                    payment: {
                        title: 'Add Payment Method',
                        continue_btn: 'Add Payment Method',
                    },
                    success: {
                        title: 'You changed your subscription plan',
                        continue_btn: 'Done',
                    },
                    error: {
                        title: 'Payment Method Error',
                        text: 'Your current payment method <0>{{cardNumber}}</0> is not working. Update your details to confirm your subscription',
                        continue_btn: 'Continue',
                    },
                },
            },
        },
    },
    balance: {
        client_balance: {
            transactions_filter: {
                all: 'All Transactions',
                subscriptions: 'Subscriptions',
                refunds: 'Refunds',
            },
        },
        trader_balance: {
            management_income: {
                title: 'Management Income',
                payout: 'Available for payout',
                total_income: 'Total Income',
                total_out: 'Total Out',
                balance_management: 'Balance Management',
                conversion_graph: {
                    title: 'Subscription Conversion: {{count}}',
                    visitors: 'Visitors: {{count}}',
                    copiers: 'Copiers: {{count}}',
                },
                income_graph: {
                    title: 'Total Income: {{amount}}',
                    total_refunds: 'Total Refunds: {{amount}}',
                },
                assets_graph: {
                    title: 'Total Client’s Assets: {{amount}}',
                    portfolios_assets: 'Total Portfolios’ Assets: {{amount}}',
                },
            },
            activity_table: {
                title: 'Table Of Activity',
                active_days: 'Total Active Days: {{count}}',
                rows: {
                    date: 'Activity',
                    visitors: 'Visitors',
                    copiers: 'Copiers',
                    income: 'Income',
                    payout: 'Payout',
                    refunds: 'Refunds',
                    available_payout: 'Available for payout',
                },
            },
        },
        transaction_history_table: {
            title: 'Transaction History',
            columns: {
                date: 'Date',
                type: {
                    title: 'Type',
                    subscription: 'Portfolio Subscription',
                    subscription_refund: 'Portfolio subscription refund',
                    payout: 'Payout',
                    income: 'Management Income',
                },
                status: {
                    title: 'Status',
                    success: 'Successful',
                    failure: 'Failed',
                },
                amount: 'Amount',
                payment_method: {
                    title: 'Payment Method:',
                    init_amount: 'Initial amount:',
                    platform_fee: 'Platform fee (20%):',
                    recieved_amount: 'Recieved Amount:',
                    order_id: 'Order ID:',
                },
            }
        },
    },
    overview: {
        balance_management: {
            back_btn: 'back',
            divider_text: 'Or',
            payment_method: {
                title: 'Balance Management',
                step: 'Step 1.',
                text: 'Choose the payment method',
                continue_btn: 'Continue',
                options: {
                    add_by_crypto: 'Add by Crypto',
                    add_by_card: 'Add by Card',
                    convert_assets: 'Convert assets to USDT',
                },
                banner_text: 'Safety first - No one has access to your funds and all account information is encrypted',
            },
            enter_amount: {
                title: 'Balance Management',
                step: 'Step 2.',
                text: 'Enter amount',
                input_label: 'You spend',
                input_placeholder: '10 - 5000',
                you_recieve: 'You recieve',
                info_text: 'By completing this transaction, you accept our <0>{{privacyPolicy}}</0> and <1>{{termsOfService}}</1>',
                privacy_policy_text: 'Privacy Policy',
                terms_of_usage_text: 'Terms of Service',
                continue_btn: 'Add balance',
            },
            convert_assets: {
                title: 'Balance Management',
                step: 'Step 2.',
                text: 'Convert Assets',
                select_all: 'Select All',
                you_recieve: 'You recieve',
                info_text: 'By completing this transaction, you accept our <0>{{privacyPolicy}}</0> and <1>{{termsOfService}}</1>',
                privacy_policy_text: 'Privacy Policy',
                terms_of_service_text: 'Terms of Service',
                continue_btn: 'Add balance',
            },
            choose_network: {
                title: 'Balance Management',
                step: 'Step 2.',
                text: 'Choose network',
                warning_title: 'Send only USDT to this deposit address',
                warning_text: 'Ensure the network is TRC 20',
                address: 'USDT Address',
                continue_btn: 'Done',
            },
            card_details: {
                title: 'Balance Management',
                step: 'Step 3.',
                text: 'Enter card details',
                card_type: {
                    label: 'Card Type',
                },
                name: {
                    label: 'Name',
                    placeholder: 'TOM SHELBY',
                },
                card_number: {
                    label: 'Card Number',
                    placeholder: '0000 0000 0000 0000',
                },
                expire_date: {
                    label: 'Expire Date',
                    placeholder: '00 / 00',
                },
                cvv: {
                    label: 'CVV',
                    placeholder: '***',
                },
                info_text: 'By completing this transaction, you accept our <0>{{privacyPolicy}}</0> and <1>{{termsOfService}}</1>',
                privacy_policy_text: 'Privacy Policy',
                terms_of_usage_text: 'Terms of Service',
                continue_btn: 'Add balance',
            },
            calculation_process: {
                text: 'Calculation Process',
            },
            success: {
                text: 'You recieved {{amount}}',
                continue_btn: 'Done',
            },
            error: {
                text: 'Something went wrong',
            },
        },
        connect_exchange: {
            back_btn: 'back',
            select_exchange: {
                title: 'Connect new exchange',
                step: 'Step 1.',
                text: 'Connect exchange',
                unavailable_options: 'Coming Soon',
                banner_text: 'Safety first - No one has access to your funds and all account information is encrypted',
                continue_btn: 'Continue',
            },
            connect_exchange: {
                title: 'Connect {{title}} exchange',
                step: 'Step 1.',
                text: 'Connect exchange',
                unavailable_options: 'Coming soon',
                hint: 'Don\'t have an account?',
                instruction: {
                    binance: [
                        '1. Click on the "Connect" button',
                        '2. Log in to your account on the website Binance',
                        '3. Confirm your connection to Endrex',
                    ],
                },
                lint_text: 'Create new {{title}} Account',
                continue_btn: 'Connect {{title}}',
            },
            select_wallet: {
                title: 'Choose wallets on exchange',
                step: 'Step 2.',
                text: 'Choose wallets',
                banner_text: 'Now you can only connect a SPOT and SPOT MARGIN wallets. We are working to connect all types of wallets to our platform',
                continue_btn: 'Add wallets',
            },
            success: {
                text: 'New Exchange Connected',
            },
            error: {
                api_key_is_used: {
                    title: 'This key has already been used',
                    text: 'It appears that these API keys for Endrex have already been set up on Binance. Please delete these keys, or use a different account to connect.',
                    support_text: 'If you need assistance, feel free to contact <0>{{linkText}}</0>',
                    support_link_text: 'Endrex support team',
                    continue_btn: 'Go to {{title}}',
                },
                connection_failure: {
                    title: 'Exchange connection failed',
                    text: 'Something went wrong. Please send us an Error report so we can address the issue.',
                    continue_btn: 'Report an Error',
                },
                something_went_wrong: {
                    title: 'Something went wrong',
                    continue_btn: 'Close',
                },
            },
            success_report: {
                title: 'Your report has been submitted. Thank you for helping us improve Endrex.',
                continue_btn: 'Close',
            },
        },
        close_portfolio: {
            title: 'Do you really want to close portfolio <0>{{portfolioName}}</0>?',
            warning: {
                title: 'Warning',
                text: 'This action is irreversible. Once closed, the portfolio type cannot be reinstated.',
            },
            description_items: [
                'Closing a trader\'s portfolio type will automatically stop all clients copy-trading activities.',
                'Clients impacted by this deletion will receive a full refund for the last month\'s subscription.',
                'Closed portfolio will be moved to the history of closed portfolios.',
            ],
            continue_btn: 'Yes, I want to delete portfolio',
            cancel_btn: 'Cancel',
        },
        connect_binance_exchange: {
            enter_data: {
                title: 'Connect Exchange',
                full_guide: 'Full Guide',
                hints: {
                    login: '1. Log in to your exchange account and go to <0>{{apiSettings}}</0>',
                    login_link_text: 'API Settings',
                    copy_address: '2. Turn on IP whitelisting and copy/paste the following list of IP addresses:',
                    paste_address: '3. Paste generated data in inputs below.',
                },
                name: {
                    label: 'Name',
                },
                api_key: {
                    label: 'API Key',
                },
                secret_key: {
                    label: 'Secret Key',
                },
                errors: {
                    only_latin: 'Name must be in English',
                    should_not_be_empty: 'Should not be empty',
                    max_50_chars: 'Maximum 50 characters',
                },
                continue_btn: 'Continue',
                hint: 'Don’t have an account?',
                hint_link: 'Create Binance Account',
            },
            success: {
                title: 'Your exchange connected!',
                continue_btn: 'Close',
            },
            error: {
                title: 'Something went wrong',
                continue_btn: 'Close',
            },
        },
        wallets: 'Wallets',
        portfolios: 'Portfolios',
        balance: 'Balance',
        full_day_change: '24h Change',
        exchange_wallets: {
            title: 'Exchange Wallets',
            connect_exchange: 'Connect New Exchange',
            hidden_wallets: 'Hidden wallets',
            card: {
                balance_lack: 'Balance is less than $50. Deposit funds to start copy trading',
                available_balance: 'Available Balance USDT',
                balance_management: 'Balance Management',
                view_data: 'View Data',
                add_portfolio: 'Add Portfolio',
                total_portfolios: 'Total Portfolios:',
                total_balance: 'Total Balance:',
                unsupported_wallet_title: 'Coming soon',
                unsupported_wallet_text: 'Endrex does not currently support this type of wallet',
                explore_marketplace: 'Explore Marketplace',
                balance: 'Balance',
                daily: '24H',
                others: 'Others',
            },
        },
        portfolios_list: {
            title: 'Trader Portfolio',
            portfolio_actions: {
                api: 'API: {{apiAddress}}',
                edit_portfolio: 'Edit Portfolio',
                view_data: 'View data on profile',
                copy_link: 'Copy Link',
                close_portfolio: 'Close Portfolio',
            },
            create_portfolio: 'Create new portfolio',
        },
        empty_states: {
            connect_exchange: {
                title: 'Start copy trading',
                content: 'To start copying and earning money, you need to connect the exchanger to your account on the overview page',
                action_title: 'Connect Exchange',
            },
        },
    },
    marketplace: {
        main_view: {
            title: 'Top portfolio for {{currentMonth}}',
            filters: {
                all: 'All',
                spot: 'Spot',
                margin: 'Margin',
                see_all: 'See all',
            },
        },
        all_portfolio_view: {
            title: 'All Portfolios ({{portfoliosCount}})',
            backlink_text: 'Back to Marketplace',
            popular_filters_title: 'Popular filters: ',
            filters: {
                search_input: {
                    placeholder: 'Portfolio name, trader name',
                },
                show_filters_btn: 'Show filters',
                show_filters_active_btn: 'Close filters',
                period: {
                    placeholder: 'Stats:',
                },
                exchanges: {
                    placeholder: 'Show:',
                },
                sort: {
                    placeholder: 'Sort:',
                },
            },
            additional_filters: {
                title: 'Additional filters:',
                subscription_price: {
                    label: 'Subscription price',
                    from_text: 'from',
                    to_text: 'to',
                },
                max_dropdown: {
                    label: 'Max Drop Down',
                    placeholder: '% max',
                },
                trader_links: {
                    label: 'Trader Links',
                },
                portfolio_lifetime: {
                    label: 'Portfolio Lifetime',
                },
                results_btn: 'Show results',
                clear_btn: 'Clear all',
            },
            portfolio_info: {
                view_profile: 'View Profile',
                trader_following: 'Following',
                trader_follow: 'Follow',
            },
        },
    },
    portfolio: {
        no_fee: 'Free',
        trade_types: {
            spot: 'Spot',
            margin: 'Margin',
            usdt: 'USDT',
            coin: 'Coin',
            usdtm: 'USDT-M',
            coinm: 'Coin-M',
            none: 'None',
        },
        switch_profile_banner: {
            switch_to_client: 'This section is available to client. Switch to your client account to see the data',
            switch_to_trader: 'This section is available to trader. Switch to your trader account to see the data',
        },
        parent_exchange: 'Parent Wallet',
        portfolio_name: 'Portfolio Name',
        portfolio_type: 'Portfolio Type',
        referral_asset: 'Referral Asset',
        portfolio_visibility: 'Portfolio Visibility',
        minimum_balance: 'Minimum Balance',
        monthly_subscription_fee: 'Monthly Subscription Fee',
        about_subscription_fee: 'More about Subscription Fee',
        past_month: 'Past Month',
        days_short: 'd',
        followers_label: 'Portfolio Copiers',
        followers_avg_monthly_return: 'Avg. Copiers Monthly Return',
        your_past_month_return: 'Your Past Month Return',
        avg_monthly_return: 'Avg. Monthly Return',
        min_balance: 'Min. Balance',
        past_week: 'Past Week',
        past_ninety_days: 'Past 90 Days',
        trader_balance: 'Trader\'s Balance',
        days_running: 'Days Running',
        trading_pair: 'Trading Pair',
        ratio: 'Ratio',
        pnl: 'PnL',
        total_pnl: 'Total Pnl',
        top_assets: 'Top Assets',
        mdd: {
            short: 'MDD',
        },
        visibility: {
            private: 'Private',
            public: 'Public',
        },
        subscription: {
            title: 'Bid-to-queue slot subscription (B2Q)',
            step: 'Step',
            subscription_success_message: 'Congratulations! You following this portfolio.',
            check_full_statistics: 'Check full statistic in overview',
            position: 'Position',
            recommended: {
                trader_balance: 'Trader Balance',
                trader_balance_tooltip: 'Trader balance tooltip',
                min_balance: 'Min. Balance',
                min_balance_tooltip: 'Minimum balance tooltip',
                stop_loss: 'Recommended Stop Loss',
                stop_loss_tooltip: 'Recommended stop tooltip',
                max_client_limit: 'Max Client Limit',
                max_client_limit_tooltip: 'Maximum client limit tooltip',
                subscription_price: 'Subscription Price',
                base_price: 'Base Price',
            },
            investment_gains_info: {
                show: 'Show Information',
                hide: 'Hide Information',
                investment_value: 'Investment value',
                investment_value_description: 'Description',
                average_monthly_return: 'Average Monthly Return',
                expected_return: 'Expected return (monthly)',
                expected_return_tooltip: 'Expected return (monthly) tooltip',
                investment_period: 'Investment Period',
                investment_gains: 'Investment gains',
                projected_return: 'Projected {{period}} average return',
                expected_end_balance: 'Expected End Balance',
            },
            steps: {
                select_exchange: {
                    label: 'Select exchange to start copying',
                    placeholder: 'Select an exchange',
                },
                enter_investment: {
                    label: 'Enter the invest amount',
                    tooltip: 'Enter the invest amount tooltip',
                    amount_label: 'Amount',
                    available: 'Available: {{balance}} USDT',
                    stop_copying: 'Stop Loss',
                    stop_copying_recommended: '{{percent}} % Recommended',
                    stop_copying_details: 'When the estimated portfolio margin balance reaches <0>{{reaches}} USDT</0>, it will trigger Stop Loss Market order to close all positions. Estimated PNL will be <0>-{{estimatedPNL}} USDT</0>',
                    stop_copying_collapsed: 'Stop Copying:',
                },
                set_queue_priority: {
                    label: 'Set Queue Priority',
                    tooltip: 'Set Queue Priority tooltip',
                    bid_amount_label: 'Enter bid amount',
                    bid_amount_tooltip: 'Enter bid amount tooltip',
                    bid_amount_available: 'Avaialable {{amount}}',
                    slot_position_label: 'Slot Position',
                    slot_position_description: 'Description',
                    delay: 'Delay',
                    historical_profit: 'Historical profit for 30 d',
                    position: 'Position',
                    your_delay: 'Your delay',
                    minimum: 'Minimum',
                    maximum: 'Maximum',
                    profit_diff_from: 'from',
                    seconds: 's',
                    you: 'You',
                    queue_details_empty: 'You are the first copyer of this portfolio. </br>Positions data will appear later',
                    error_low_balance: 'Low Balance',
                    add_balance: 'Add Balance',
                },
                payment_method: {
                    label: 'Payment Method',
                    tooltip: 'Payment Method tooltip',
                    select_method_tooltip: 'Select Method',
                    select_method_collapsed: '{{methodName}} Acc: {{cardNumber}}',
                    placeholder: 'Select method',
                },
            },
            start_copy_trade: 'Start Copy',
            stop_copy_trade: 'Stop Copying',
            add_new_exchange: 'Add new exchange',
            deposit: 'Deposit',
            exchange_balance_error: 'The balance of this exchange has changed and now the balance is not enough',
            queue_error: 'The queue has run out. Try later if master will open new slots',
            queue_position: 'Slot position',
            queue_position_description: 'Description',
        },
        empty: {
            my_trader_no_active_portfolios: {
                title: 'Create portfolios',
                body: 'You don\'t have active portfolios. Connect an exchange and create portfolio',
                btn_text: 'Create Portfolio',
            },
            my_trader_no_exchanges: {
                title: 'Connect an exchange',
                body: 'You don\'t have active portfolios. Connect an exchange and create portfolio',
            },
            trader: {
                title: 'This trader has no open portfolios',
                body: 'Find other traders on the marketplace and start copying',
            },
            client: {
                title: 'Client has no open portfolios',
                body: 'Find traders on the marketplace and start copying',
            },
            client_my_profile: {
                title: 'Start copy trading',
                body: 'To start copying and earning money, you need to connect the exchanger to your account on the overview page',
            },
        },
        add_portfolio_button: 'Add portfolio from Marketplace',
        connection_failed: 'Connection Failed',
        api_connection_failure: 'API connection failure',
        please_update_api_key: 'Please update API key',
        update_api_key: 'Update API key',
        reconnect: 'Reconnect',
        assessment_review: 'Assessment Review',
        history: 'History',
        archieved: 'Closed {{date}}',
        overview: 'Overview',
        copiers: 'Copiers',
        share_link: 'Share Link',
        recommended_stop_loss: 'Recommended Stop-Loss',
        actions: {
            close: 'Close Portfolio',
        },
        portfolio: 'Portfolio',
        portfolio_one: '{{count}} Portfolio',
        portfolio_few: '{{count}} Portfolios',
        portfolio_many: '{{count}} Portfolios',
        portfolio_other: '{{count}} Portfolio',
    },
    table: {
        manual_intervention: 'Manual intervention',
        order_size: '{{percent}}% of balance',
        common: {
            no_data: 'No Data',
            days_range: {
                thirty_days: '30 D',
                one_week: '1 W',
                one_day: '1 D',
                all_time: 'All time',
            },
        },
        copiers: {
            delay: 'Delay',
            copier: 'Copier',
            all_time_tooltip: 'All time tooltip',
            subscription_fee: 'Subscription',
            exchange: 'Exchange',
            duration: 'Duration',
            month_total: 'Total profit for past month',
        },
        followers: {
            empty: 'No followers',
            empty_for_user_profile: 'You have no followers',
        },
        following: {
            empty: 'No followings',
            empty_for_user_profile: 'You are not following anybody yet',
        },
        current_positions: {
            asset: 'Asset',
            order_size: 'Order Size',
            entry_price: 'Entry Price',
            pnl: 'PNL (ROE)',
            duration: 'Duration',
            trades_done: '{{count}} Trades',
        },
    },
    support: {
        faq: 'FAQ',
        articles: {
            title: 'Articles',
            read_article: 'Read',
            hide_articles: 'Hide',
            show_articles: 'Show all',
        },
        send_request: {
            title: 'Send request',
            text: 'Do you have any questions, suggestions or feedback? We’d love to hear from you!',
            name: {
                title: 'Your name',
                placeholder: 'Enter name',
            },
            email: {
                title: 'E-mail',
                placeholder: 'Enter email',
            },
            message: {
                title: 'About',
                placeholder: 'Tell about your problem',
            },
            attach: {
                title: 'Attach Photo',
                hint: 'JPG or PNG. Max size of 15 MB',
                errors: {
                    exceeded_file_size: 'File is too large. Choose up 15 MB',
                    invalid_file_type: 'Format not supported. Only JPG or PNG.',
                },
            },
            topic: {
                title: 'Topic',
                placeholder: 'Enter topic',
            },
            messages: {
                name_empty: 'Should not be empty',
                email_empty: 'Should not be empty',
                email_invalid: 'E-mail address not valid',
                exceeded_file_size: 'File is too large. Choose up 15 MB',
                invalid_file_type: 'Format not supported. Only JPG or PNG.',
            },
            submit_btn: 'Send',
        },
        support_banner_text: 'Do you have any questions? Check our FAQ or if you still haven’t found the answer, write to us',
    },
    errors: {
        not_found: {
            title: '404. Page not found',
            back_btn: 'back',
            home_btn: 'Go to Home',
        },
        unknown_error: {
            title: 'Something went wrong',
            back_btn: 'back',
            reload_btn: 'Reload',
        },
    },
};
export default enTranslation;
